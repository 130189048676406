export function recordClientSideEvent(
  eventName: string,
  parameters: Record<string, string | null | boolean | undefined>,
) {
  fetch('/record-event', {
    method: 'post',
    body: JSON.stringify({
      event: eventName,
      parameters,
    }),
  });
}
